@font-face {
  font-family: 'Factor-A';
  src: url(https://cdn.getpesto.com/email-fonts/Factor-A-Light.woff) format('woff'),
    url(./fonts/FactorA/Factor-A-Light.ttf) format('truetype');
  font-display: swap;
  unicode-range: U+0000-00FF;
}

@font-face {
  font-family: 'Factor-A';
  src: url(https://cdn.getpesto.com/email-fonts/Factor-A-Black.woff) format('woff'),
    url(./fonts/FactorA/Factor-A-Black.ttf) format('truetype');
  font-display: swap;
  unicode-range: U+0000-00FF;
}

@font-face {
  font-family: 'Factor-A';
  src: url(https://cdn.getpesto.com/email-fonts/Factor-A-Medium.woff) format('woff'),
    url(./fonts/FactorA/Factor-A-Medium.ttf) format('truetype');
  font-display: swap;
  unicode-range: U+0000-00FF;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  height: 100%;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Toastify__toast-container {
  z-index: 1000000 !important;
}

.Toastify__toast {
  background-color: #17232f !important;
  color: #fdfdfc !important;
  font-family: 'Factor-A-Medium' !important;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(76, 76, 76);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-wrapper {
  width: 30%;
}

label {
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}

input {
  display: block;
  font-size: 18px;
  width: 100%;
  padding: 7px;
}

button {
  width: 30%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 25px;
  height: 25px;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
}

#hubspot-conversations-iframe {
  top: -85%;
  pointer-events: all;
}

@media (min-width: 600px) {
  #hubspot-conversations-iframe {
    top: -105%;
    pointer-events: all;
  }

  #hubspot-messages-iframe-container {
    top: 95% !important;
    pointer-events: none;
  }
}

#hubspot-messages-iframe-container {
  top: 85%;
  pointer-events: none;
}

.rainbow-gradient {
  background: conic-gradient(
    from 210deg at 50% 50%,
    #f1c6dd 0deg,
    #c1a4e8 50.05925238132477deg,
    #b8e2fb 85.93856155872345deg,
    #f2efe8 134.97360706329346deg,
    #f9dcdd 172.04889178276062deg,
    #e1c1e5 200.75233697891235deg,
    #bdafe3 224.6718692779541deg,
    #c7edeb 259.35521364212036deg,
    #e7f5eb 298.8224387168884deg,
    #f2f0e7 328.72185945510864deg,
    #ddc1e1 360deg
  );
}
.light-rainbow-gradient {
  background: conic-gradient(
      from 210deg at 50% 50%,
      rgba(241, 198, 221, 0.2) 0deg,
      rgba(193, 164, 232, 0.2) 50.05925238132477deg,
      rgba(184, 226, 251, 0.2) 85.93856155872345deg,
      rgba(242, 239, 232, 0.2) 134.97360706329346deg,
      rgba(249, 220, 221, 0.2) 172.04889178276062deg,
      rgba(225, 193, 229, 0.2) 200.75233697891235deg,
      rgba(189, 175, 227, 0.2) 224.6718692779541deg,
      rgba(199, 237, 235, 0.2) 259.35521364212036deg,
      rgba(231, 245, 235, 0.2) 298.8224387168884deg,
      rgba(242, 240, 231, 0.2) 328.72185945510864deg,
      rgba(221, 193, 225, 0.2) 360deg
    ),
    #fff;
}

.salsa-gradient {
  background: linear-gradient(0deg, #f93549, #f93549),
    linear-gradient(270deg, rgba(65, 102, 36, 0.2) 0.76%, rgba(0, 0, 0, 0) 92.7%);
}
