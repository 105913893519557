.caratSlider {
  position: relative;
  flex: auto;
  height: 34px;
  margin-top: 20px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.caratSlider :global .rc-slider-handle {
  position: absolute;
  top: 2px;
  width: 24px;
  height: 24px;
  cursor: grab;
  background-color: #4b5157;
  border: solid 2px #17232f;
  border-radius: 50%;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(253, 253, 252, 0.1);
  z-index: 16;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.caratSlider :global .rc-slider-handle-dragging {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  cursor: grabbing;
}

.caratSlider :global .rc-slider-rail {
  position: absolute;
  top: 14px;
  border-radius: 4px;
  border: #000 solid 1px;
  transform: translateY(-50%);
  width: 100%;
  height: 8px;
  background-color: #fff;
  z-index: 15;
}

.caratSlider :global .rc-slider-track {
  background-color: #4b5157;
  position: absolute;
  top: 14px;
  border-radius: 4px;
  border: #000000 solid 1px;
  transform: translateY(-50%);
  height: 8px;
  z-index: 16;
}

.caratSlider :global .rc-slider-dot {
  position: absolute;
  transform: translateY(-50%);
  width: 1px;
  height: 28px;
  background-color: #000;
  z-index: 9;
}
.caratSlider :global .rc-slider-dot:first-child,
.caratSlider :global .rc-slider-dot:last-child {
  display: none;
}

.caratSlider :global .rc-slider-mark {
  position: absolute;
  top: -18px;
  left: 0;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  color: #4b5157;
}
.caratSlider :global .rc-slider-mark-text:first-child {
  transform: translateX(0%) !important;
}
.caratSlider :global .rc-slider-mark-text:last-child {
  transform: translateX(-100%) !important;
}

.caratSlider :global .rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #4b5157;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.caratSlider :global .rc-slider-mark-text-active {
  color: #666;
}
